import { makeAutoObservable } from 'mobx';
import { PeriodTypes, generatePeriodName } from '@aider/aider-period-library';
import { PracticeTypes } from '@aider/constants-library';
import { DateTime } from 'luxon';
import { DateFormats, ValueTypes } from '@aider/aider-formatting-library';
import _ from 'lodash';
import { PromptType } from '../models/interfaces/components';
import type { RootStore } from './Store';
import { NotApplicable } from '../models/constants/components';
import { generateUnorderedListBlock } from '../lib/storeUtils';

export default class TemplateTextStore {
  rootStore: RootStore;

  selectedPeriodMetricKeys = {
    revenue: 'selectedPeriodRevenue',
    operationalExpenses: 'selectedPeriodOpex',
    directCosts: 'selectedPeriodDirectCosts',
    netProfit: 'selectedPeriodNetProfit',
    grossProfit: 'selectedPeriodGrossProfit',
  };

  selectedFyMetricKeys = {
    revenue: 'selectedFyRevenue',
    operationalExpenses: 'selectedFyOpex',
    directCosts: 'selectedFyDirectCosts',
    netProfit: 'selectedFyNetProfit',
    grossProfit: 'selectedFyGrossProfit',
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getProfitabilityInsightText(insightKey: string, budget: string = 'historical') {
    let trendTemplate = '';
    const insightName = `${insightKey}_insightName`;
    const currentPeriodName = 'profitability_periodName';
    const currentPeriod = 'profitability_currentPeriod';

    const financialYear = 'financialYearEndYear';

    const currentVsLastYearPercentage = `${insightKey}_currentVsLastYear_percentage`;
    const currentVsLastYearTrend = `${insightKey}_currentVsLastYear_relation`;

    const currentVsLastPercentage = `${insightKey}_currentVsLast_percentage`;
    const currentVsLastTrend = `${insightKey}_currentVsLast_relation`;

    const yearToDateAmount = `${insightKey}_fyToCurrentPeriod`;

    const yearToDateVsLastYearPercentage = `${insightKey}_fyToCurrentPeriodVsLastFYToCurrentPeriod_percentage`;
    const yearToDateVsLastYearTrend = `${insightKey}_fyToCurrentPeriodVsLastFYToCurrentPeriod_relation`;

    const yearToDateVsPreviousYearPercentage = `${insightKey}_fyToCurrentPeriodVsPreviousFYToCurrentPeriod_percentage`;
    const yearToDateVsPreviousYearTrend = `${insightKey}_fyToCurrentPeriodVsPreviousFYToCurrentPeriod_relation`;

    const periodBudgetAmount = `${insightKey}Budget_${budget}`;

    const periodVsBudgetPerecentage = `${insightKey}Budget_periodVsbudget_percentage_${budget}`;
    const periodVsBudgetaboveBelow = `${insightKey}Budget_periodVsbudget_aboveBelow_${budget}`;

    const fyToPeriodBudgetAmount = `${insightKey}Budget_fyToCurrentPeriod_${budget}`;
    const fyToPeriodVsBudgetpercentage = `${insightKey}Budget_fyToCurrentPeriodVsBudget_percentage_${budget}`;
    const fyToPeriodVsBudgetaboveBelow = `${insightKey}Budget_fyToCurrentPeriodVsBudget_aboveBelow_${budget}`;
    const budgetName = `budgetName_${budget}`;

    const textParams = [];

    trendTemplate += `#{${currentPeriodName}} #{${insightName}} is #{${insightKey}}.\n`;

    let selection: PracticeTypes.ReportContentBlock = {
      type: 'unordered-list-item',
      settings: {
        anchorOffset: 0,
        focusOffset: trendTemplate.length,
        inlineStyle: 'BOLD'
      },
    };

    if (budget === 'historical') {
      trendTemplate += `This is #{${currentVsLastYearTrend}}  #{${currentVsLastYearPercentage}} from the same period last year, `;
      trendTemplate += `and #{${currentVsLastTrend}} #{${currentVsLastPercentage}} from the previous period.`;
    } else {
      trendTemplate += `= #{${periodVsBudgetPerecentage}} #{${periodVsBudgetaboveBelow}} #{${budgetName}} target of #{${periodBudgetAmount}}.`;
    }

    textParams.push({ ...selection, text: trendTemplate });

    trendTemplate = `#{${insightName}} for financial year #{${financialYear}} to #{${currentPeriod}} is #{${yearToDateAmount}}.\n`;

    selection = {
      type: 'unordered-list-item',
      settings: {
        anchorOffset: 0,
        focusOffset: trendTemplate.length,
        inlineStyle: 'BOLD'
      }
    };

    if (budget === 'historical') {
      trendTemplate += `This is #{${yearToDateVsLastYearTrend}} #{${yearToDateVsLastYearPercentage}} from the same period last year, `;
      trendTemplate += `and #{${yearToDateVsPreviousYearTrend}} #{${yearToDateVsPreviousYearPercentage}} from the year before.`;
    } else {
      trendTemplate += `= #{${fyToPeriodVsBudgetpercentage}} #{${fyToPeriodVsBudgetaboveBelow}} #{${budgetName}} target of #{${fyToPeriodBudgetAmount}}.`;
    }

    textParams.push({ ...selection, text: trendTemplate });

    return textParams;
  }

  getProfitabilityInsightVariables(insightKey: string, datapoints: any) {
    const selectedPeriodStart = DateTime.fromISO(datapoints?.selectedPeriodStart);
    const selectedPeriodEnd = DateTime.fromISO(datapoints?.selectedPeriodEnd);

    let periodShift;
    switch (this.rootStore.timePeriodStore.periodGranularity) {
      case PeriodTypes.QUARTERLY:
        periodShift = { months: 3 };
        break;
      default:
        periodShift = { months: 1 };
    }

    const priorPeriodStart = selectedPeriodStart.minus(periodShift).startOf('month');
    const priorPeriodEnd = selectedPeriodEnd.minus(periodShift).endOf('month');

    const samePeriodPriorYearStart = selectedPeriodStart.minus({ years: 1 }).startOf('month');
    const samePeriodPriorYearEnd = selectedPeriodEnd.minus({ years: 1 }).endOf('month');

    const selectedFinancialYear = DateTime.fromISO(datapoints?.selectedFinancialYearEndDate);
    const financialYearMinusOne = selectedFinancialYear.minus({ years: 1 });
    const financialYearMinusTwo = selectedFinancialYear.minus({ years: 2 });

    const { format } = this.rootStore.localeStore;

    const variables = {
      [`${insightKey}_insightName`]: this.rootStore.localeStore.translation(`insights.${insightKey}.title`),
      profitability_periodName: generatePeriodName(selectedPeriodStart.toISODate(), selectedPeriodEnd.toISODate()),
      profitability_currentPeriod: this.rootStore.localeStore.formatDate(selectedPeriodEnd.toISODate(), DateFormats.fullMonth),
      profitability_priorPeriodName: generatePeriodName(priorPeriodStart.toISODate(), priorPeriodEnd.toISODate()),
      profitability_samePeriodPriorYearName: generatePeriodName(samePeriodPriorYearStart.toISODate(), samePeriodPriorYearEnd.toISODate()),
      financialYearEndYear: this.rootStore.localeStore.formatDate(selectedFinancialYear.toISODate(), DateFormats.fullYear),
      priorFinancialYearEndYear: this.rootStore.localeStore.formatDate(financialYearMinusOne.toISODate(), DateFormats.fullYear),
      twoPriorFinancialYearEndYear: this.rootStore.localeStore.formatDate(financialYearMinusTwo.toISODate(), DateFormats.fullYear),
      [insightKey]: format.formatValue({ value: datapoints?.selectedPeriodRevenue, format: ValueTypes.currency }),
      [`${insightKey}_currentVsLastYear_percentage`]: format.formatValue({ value: Math.abs(datapoints?.samePeriodPreviousYearDifference), format: ValueTypes.percentage }),
      [`${insightKey}_currentVsLastYear_relation`]: datapoints?.samePeriodPreviousYearDifference >= 0 ? 'higher' : 'lower',
      [`${insightKey}_currentVsLast_percentage`]: format.formatValue({ value: Math.abs(datapoints?.previousPeriodDifference), format: ValueTypes.percentage }),
      [`${insightKey}_currentVsLast_relation`]: datapoints?.previousPeriodDifference >= 0 ? 'higher' : 'lower',
      [`${insightKey}_fyToCurrentPeriod`]: format.formatValue({ value: datapoints.selectedFyRevenue, format: ValueTypes.currency }),
      [`${insightKey}_fyToCurrentPeriodVsLastFYToCurrentPeriod_percentage`]: format.formatValue({ value: Math.abs(datapoints?.onePreviousFinancialYearDifference), format: ValueTypes.percentage }),
      [`${insightKey}_fyToCurrentPeriodVsLastFYToCurrentPeriod_relation`]: datapoints?.onePreviousFinancialYearDifference >= 0 ? 'higher' : 'lower',
      [`${insightKey}_fyToCurrentPeriodVsPreviousFYToCurrentPeriod_percentage`]: format.formatValue({ value: Math.abs(datapoints?.twoPreviousFinancialYearDifference), format: ValueTypes.percentage }),
      [`${insightKey}_fyToCurrentPeriodVsPreviousFYToCurrentPeriod_relation`]: datapoints?.twoPreviousFinancialYearDifference >= 0 ? 'higher' : 'lower',
    };

    return variables;
  }

  getProfitabilityV2InsightText(variables: any = null) {
    const { isBudgetsActive } = this.rootStore.insightStore;

    /**
      * The following template strings need to be formatted precisely as the
      * line breaks and indentation are carried over to the final text.
      * As such when breaking a line to prevent it being absurdly long, ensure
      * to escape the line break with a trailing backslash, and start the new line
      * with 0 spaces at the beginning to make it continuous.
      */
    const periodValue = `- **#{selectedPeriod} #{insightName} is \
#{currentPeriodValue}**
`;

    const financialYearValue = `- **#{insightName} for financial year \
#{financialYear} to #{selectedPeriod} is #{yearToDateValue}**
`;

    let periodComparison;
    let financialYearComparison;

    if (isBudgetsActive) {
      periodComparison = `  = #{periodBudgetTrendPercentage} \
#{periodBudgetTrendDirection} #{budgetName} target of #{periodBudgetTarget}.
`;

      financialYearComparison = `  = #{yearBudgetTrendPercentage} \
#{yearBudgetTrendDirection} #{budgetName} target of #{yearBudgetTarget}.
`;
    } else {
      periodComparison = `  This is #{periodLastYearTrendDirection} \
#{periodLastYearTrendPercentage} from the same period last year, and \
#{priorPeriodTrendDirection} #{priorPeriodTrendPercentage} from the previous \
period.
`;
      financialYearComparison = `  This is #{yearLastYearTrendDirection} \
#{yearLastYearTrendPercentage} from the same period last year, and \
#{twoPriorYearTrendDirection} #{twoPriorYearTrendPercentage} from the \
year before.
`;
    }

    let template: string = '';
    if (
      variables?.selectedPeriod
      && variables?.insightName
      && variables?.currentPeriodValue
    ) {
      template += periodValue;
      if (
        (
          isBudgetsActive
          && variables?.periodBudgetTrendPercentage
          && variables?.periodBudgetTrendPercentage !== NotApplicable
          && variables?.periodBudgetTarget
          && variables?.periodBudgetTarget !== NotApplicable
        ) || (
          !isBudgetsActive
          && variables?.periodLastYearTrendPercentage
          && variables?.periodLastYearTrendPercentage !== NotApplicable
          && variables?.priorPeriodTrendPercentage
          && variables?.priorPeriodTrendPercentage !== NotApplicable
        )
      ) {
        template += periodComparison;
      }
    }

    if (
      variables?.yearToDateValue
      && variables?.yearBudgetTrendPercentage
      && variables?.yearBudgetTarget
    ) {
      template += financialYearValue;

      if (
        (
          isBudgetsActive
          && variables?.yearBudgetTrendPercentage
          && variables?.yearBudgetTrendPercentage !== NotApplicable
          && variables?.yearBudgetTarget
          && variables?.yearBudgetTarget !== NotApplicable
        ) || (
          !isBudgetsActive
          && variables?.yearLastYearTrendPercentage
          && variables?.yearLastYearTrendPercentage !== NotApplicable
          && variables?.twoPriorYearTrendPercentage
          && variables?.twoPriorYearTrendPercentage !== NotApplicable
        )
      ) {
        template += financialYearComparison;
      }
    }
    return template;
  }

  getProfitabilityV2InsightVariables(insightKey: string, datapoints: any) {
    const selectedPeriodMetricKey = this.selectedPeriodMetricKeys[insightKey];
    const selectedFyMetricKey = this.selectedFyMetricKeys[insightKey];

    const vars = {
      selectedPeriod: generatePeriodName(
        datapoints?.selectedPeriodStart,
        datapoints?.selectedPeriodEnd
      ),
      insightName: this.rootStore.localeStore.translation(
        `insights.${insightKey}.title`
      ),
      currentPeriodValue: this.rootStore.localeStore.format.formatValue({
        value: datapoints?.[selectedPeriodMetricKey],
        format: ValueTypes.currency
      }),
      periodBudgetTrendDirection: datapoints?.selectedPeriodBudgetPercentage >= 0
        ? 'above'
        : 'below',
      periodBudgetTrendPercentage:
        _.defaultTo(datapoints?.selectedPeriodBudgetPercentage, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: Math.abs(datapoints.selectedPeriodBudgetPercentage),
            format: ValueTypes.percentage
          }),
      periodBudgetTarget:
        _.defaultTo(datapoints?.selectedPeriodBudgetAmount, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: datapoints.selectedPeriodBudgetAmount,
            format: ValueTypes.currency
          }),
      budgetName: this.rootStore.insightStore.selectedBudgetName || 'selected budget',
      periodType: null,
      periodLastYearTrendDirection: datapoints?.samePeriodPreviousYearDifference >= 0
        ? 'up'
        : 'down',
      periodLastYearTrendPercentage:
        _.defaultTo(datapoints?.samePeriodPreviousYearDifference, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: Math.abs(datapoints.samePeriodPreviousYearDifference),
            format: ValueTypes.percentage
          }),
      priorPeriodTrendDirection: datapoints?.previousPeriodDifference >= 0
        ? 'up'
        : 'down',
      priorPeriodTrendPercentage:
        _.defaultTo(datapoints?.previousPeriodDifference, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: Math.abs(datapoints.previousPeriodDifference),
            format: ValueTypes.percentage
          }),
      financialYear: this.rootStore.localeStore.formatDate(
        datapoints?.selectedFinancialYearEndDate,
        DateFormats.fullYear
      ),
      yearToDateValue: this.rootStore.localeStore.format.formatValue({
        value: datapoints?.[selectedFyMetricKey],
        format: ValueTypes.currency
      }),
      yearBudgetTrendDirection: datapoints?.selectedFyBudgetDifference >= 0
        ? 'above'
        : 'below',
      yearBudgetTrendPercentage:
        _.defaultTo(datapoints?.selectedFyBudgetDifference, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: Math.abs(datapoints.selectedFyBudgetDifference),
            format: ValueTypes.percentage
          }),
      yearBudgetTarget:
        _.defaultTo(datapoints?.selectedFyBudgetAmount, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: datapoints.selectedFyBudgetAmount,
            format: ValueTypes.currency
          }),
      yearLastYearTrendDirection: datapoints?.onePreviousFinancialYearDifference >= 0
        ? 'up'
        : 'down',
      yearLastYearTrendPercentage:
        _.defaultTo(datapoints?.onePreviousFinancialYearDifference, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: Math.abs(datapoints.onePreviousFinancialYearDifference),
            format: ValueTypes.percentage
          }),
      twoPriorYearTrendDirection: datapoints?.twoPreviousFinancialYearDifference >= 0
        ? 'up'
        : 'down',
      twoPriorYearTrendPercentage:
        _.defaultTo(datapoints?.twoPreviousFinancialYearDifference, NotApplicable) === NotApplicable
          ? NotApplicable
          : this.rootStore.localeStore.format.formatValue({
            value: Math.abs(datapoints?.twoPreviousFinancialYearDifference || 0),
            format: ValueTypes.percentage
          }),
    };

    switch (this.rootStore.timePeriodStore.periodGranularity) {
      case PeriodTypes.QUARTERLY:
        vars.periodType = 'quarter';
        break;
      case PeriodTypes.MONTHLY:
        vars.periodType = 'month';
        break;
      default:
        vars.periodType = 'period';
    }

    return vars;
  }

  // eslint-disable-next-line class-methods-use-this
  getCashFlowInsightText() {
    let shareTextTemplate = '';

    const firstDayOfMonth = 'cashFlow_firstDayOfPeriod';
    const startOfMonthString = 'cashFlow_periodStartMonth';
    const endDayOfMonth = 'cashFlow_periodEndDate';
    const startingCashPosition = 'closingCashPosition';
    const estimatedNetCashFlow = 'estimatedNetCashFlow';
    const estimatedEndCashPosition = 'estimatedCashPosition';

    shareTextTemplate += `This is a cash position estimate driven by your historical trend and predicted future bank transactions in the #{${startOfMonthString}}.\n`;
    shareTextTemplate += `The starting cash position for #{${firstDayOfMonth}} is #{${startingCashPosition}}.\n\n`;
    shareTextTemplate += `The #{${startOfMonthString}} net cash flow is predicted to be around #{${estimatedNetCashFlow}}.\n`;
    shareTextTemplate += `Therefore the estimated cash position for #{${endDayOfMonth}} is #{${estimatedEndCashPosition}}.\n`;

    return [{ text: shareTextTemplate }];
  }

  // eslint-disable-next-line class-methods-use-this
  getCashFlowActualInsightText() {
    let shareTextTemplate = '';
    const lastMonth = 'cashFlowActual_periodEndMonth';
    const previousMonth = 'cashFlowActual_priorPeriodEndMonth';
    const currentnetcashFlow = 'netCashFlow';
    const lastnetcashFlow = 'netCashFlow_last';
    const currentVsLastCashFlow = 'netCashFlow_currentVsLast_percentage';
    const currentNetCashFlowTrend = 'netCashFlow_currentVsLast_relation';
    const currentVsLastEndCashPosition = 'endCashPosition_currentVsLast_percentage';
    const currentVsLastEndCashPositionTrend = 'endCashPosition_currentVsLast_relation';

    shareTextTemplate += `#{${lastMonth}}'s net cash flow of #{${currentnetcashFlow}} shows a #{${currentVsLastCashFlow}} #{${currentNetCashFlowTrend}} `;
    shareTextTemplate += `in cash compared to #{${previousMonth}}'s net cash flow of #{${lastnetcashFlow}} .\n\n`;

    shareTextTemplate += `The end cash position for #{${lastMonth}}'s and #{${previousMonth}}'s \n`;
    shareTextTemplate += `shows a #{${currentVsLastEndCashPosition}} #{${currentVsLastEndCashPositionTrend}} in cash. \n\n`;

    shareTextTemplate += 'Please let me know if you\'d like to discuss cashflow options and cash flow management further.\n';

    return [{ text: shareTextTemplate }];
  }

  // eslint-disable-next-line class-methods-use-this
  getGSTForecast() {
    let shareTextTemplate = '';

    const gstDueDate = 'salesTax_dueDate';
    const gstPeriod = 'salesTax_periodName';
    const paymentForecast = 'estimatedSalesTax';
    const gstCompletionRate = 'salesTax_completionRate_percentage';

    shareTextTemplate += `The next GST payment is due on #{${gstDueDate}} for the #{${gstPeriod}} period.\n\n`;
    shareTextTemplate += `The GST period recordkeeping is #{${gstCompletionRate}} complete and the forecasted amount is around `;
    shareTextTemplate += `#{${paymentForecast}} due based on the record income and expenses so far.\n\n`;
    shareTextTemplate += `If payment is due, make sure you set enough money aside for #{${gstDueDate}}.\n\n`;

    return [{ text: shareTextTemplate }];
  }

  // eslint-disable-next-line class-methods-use-this
  getIncomeTaxInsightText() {
    let shareTextTemplate = '';

    const currentMonth = 'incomeTax_periodEndMonth';
    const financialYearincomeTax = 'incomeTax_fyToCurrentPeriod';
    const lastYearIncomeTax = 'incomeTax_lastFY';
    const financialVslastYearPercentage = 'incomeTax_fyToCurrentPeriodVsLastFYToCurrentPeriod_percentage';
    const financialVslastIncrDcrTrend = 'incomeTax_fyToCurrentPeriodVsLastFYToCurrentPeriod_relation';
    const financialVslastMoreLessTrend = 'incomeTax_fyToCurrentPeriodVsLastFYToCurrentPeriod_moreOrLess';

    shareTextTemplate += `Looking at the year to date net profit - the income tax forecast up to #{${currentMonth}} is around #{${financialYearincomeTax}}, `;
    shareTextTemplate += `this is a #{${financialVslastYearPercentage}} #{${financialVslastIncrDcrTrend}} compared to #{${lastYearIncomeTax}} for the same period last year.\n\n`;
    shareTextTemplate += `You will need #{${financialVslastYearPercentage}} #{${financialVslastMoreLessTrend}} money than the last year set aside for income tax payable.\n\n`;
    return [{ text: shareTextTemplate }];
  }

  // eslint-disable-next-line class-methods-use-this
  getInvoiceStatus() {
    const currentMonth = 'invoice_periodEndMonth';
    const invoiceCount = 'invoiceCount';
    const invoiceAmount = 'invoice';
    const averageInvoiceCount = 'averageInvoiceCount';
    const averageMonthlyInvoiceAmount = 'averageMonthlyInvoiceAmount';
    const percentageDifferenceFormatted = 'invoice_currentVsAverage_percentage';
    const increaseDecrease = 'invoice_currentVsAverage_relation';
    let trendTemplate = '';
    const textParams = [];

    trendTemplate = `In #{${currentMonth}} you sent #{${invoiceCount}} invoices so far with a total value of #{${invoiceAmount}}.`;
    textParams.push(generateUnorderedListBlock(trendTemplate));

    trendTemplate = `On average you send out #{${averageInvoiceCount}} invoices with a total of #{${averageMonthlyInvoiceAmount}} by this point of the month.`;
    textParams.push(generateUnorderedListBlock(trendTemplate));

    trendTemplate = `#{${currentMonth}} invoicing is currently #{${percentageDifferenceFormatted}} #{${increaseDecrease}} than average.`;
    textParams.push(generateUnorderedListBlock(trendTemplate));

    trendTemplate = 'If you have any amounts that you have not invoiced yet, you may want to invoice them soon.';
    textParams.push(generateUnorderedListBlock(trendTemplate));

    return textParams;
  }

  rewritePrompt = 'Rewrite the following in plain English using #{clientCountry} spelling & a casual tone for a performance report. Omit any greetings. Keep any heading formatting from the original text.\n';

  executiveSummaryPrompt = 'Draft a very short bullet-pointed executive summary of my business client\'s financial performance. Focus on any significant change that affects financial viability of #{clientCountry} small business #{clientIndustry}. Write in plain English, use #{clientCountry} spelling. Omit context intro, headline, & follow-up actions.\n';

  actionPointsPrompt = 'Based on the financial performance data supplied, suggest 3 action points that a small company in #{clientIndustry} industry in #{clientCountry} can take to improve their financial situation. Use line breaks & numbered list. Write in plain English, use #{clientCountry} spelling.  Omit context intro, headline, & performance summary.\n';

  getLLmPrompt(promptType: PromptType) {
    switch (promptType) {
      case 'rewrite':
        return this.rewritePrompt;
      case 'summary':
        return this.executiveSummaryPrompt;
      case 'action':
        return this.actionPointsPrompt;
      default:
        return '';
    }
  }

  getTrendTextTemplate(insightKey: string, version: number = 1, variables: any = null) {
    switch (insightKey) {
      case 'revenue':
      case 'netProfit':
      case 'grossProfit':
      case 'operationalExpenses':
      case 'directCosts':
        return version === 2
          ? this.getProfitabilityV2InsightText(variables)
          : this.getProfitabilityInsightText(insightKey);
      case 'cashFlow':
        return this.getCashFlowInsightText();
      case 'cashFlowActual':
        return this.getCashFlowActualInsightText();
      case 'gst':
        return this.getGSTForecast();
      case 'incomeTax':
        return this.getIncomeTaxInsightText();
      case 'invoiceStatus':
        return this.getInvoiceStatus();
      default:
        return '';
    }
  }

  getTrendTextVariables(insightKey: string, datapoints: any) {
    switch (insightKey) {
      case 'revenue':
      case 'netProfit':
      case 'grossProfit':
      case 'operationalExpenses':
      case 'directCosts':
        return this.getProfitabilityV2InsightVariables(insightKey, datapoints);
      default:
        return datapoints;
    }
  }
}
