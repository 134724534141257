import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, MenuProps, Select } from 'antd';
import { SplitEnums } from '@aider/constants-library';
import { DownOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';
import CheckList from './CheckList';
// eslint-disable-next-line import/no-named-as-default
import Insight from './Insight';
import { Welcome } from '../../entities/components';
import notes from '../../entities/icons/notes.png';
import { TabItems } from '../../entities/types';
import { InsightComponentProps } from '../../ts/interfaces/components/insights';

import { InsightType } from '../../ts/enums/Constants';
import PeriodGranularity from '../../components/PeriodGranularity';
import AdvisoryAi from './AdvisoryAi';
import PerformanceReport from './PerformanceReport';
import { ShimmerLoader } from '../../components/dataCompliance/ShimmerLoader';
import { useStore } from '../../stores/Store';
import { InsightTab } from '../../stores/v1/pageStore';
import Overview from '../../components/clientTransactionSummary/Overview';
import PerformanceReportV2 from '../../components/reports/PerformanceReport';
import Notification from '../../components/Notification';
import ButtonSecondary from '../../components/customAntD/ButtonSecondary';
import NoBudgetsBanner from '../../components/insights/NoBudgetsBanner';
import TextPill from '../../components/TextPill';

const InsightComponent = ({
  renderActionCentreModal,
  type,
  uppercaseType,
  lowercaseType,
  toggleModal,
}: InsightComponentProps) => {
  const rootStore = useStore();
  const { checklistStore, businessesStore, insightStore, splitStore } = rootStore;
  const { insightData, insightsLoading } = insightStore;

  useEffect(() => {
    if (insightData) {
      rootStore.timePeriodStore.getInsightOptions(insightData);
      if (!rootStore.timePeriodStore.profitabilityPeriodSelected) {
        rootStore.timePeriodStore.setProfitabilityPeriodSelected(rootStore.timePeriodStore.profitabilityPeriods[0].value);
        insightStore.selectedProfitabilityPeriod = rootStore.timePeriodStore.profitabilityPeriods[0].value;
      }
    }
  }, [insightData, rootStore.timePeriodStore.profitabilityPeriodSelected]);

  if (
    insightsLoading && type !== 'DataHealth' && !rootStore.businessStore.isRuleLoading
  ) {
    return <ShimmerLoader />;
  }

  if (!insightData || !(insightData?.length > 0)) {
    if (!checklistStore.businessChecklists.get(businessesStore.selectedBusinessId) && type === 'DataHealth') {
      return <ShimmerLoader />;
    }
    return (
      <div style={{ width: '100%', paddingTop: 20 }}>
        <Welcome
          img={notes}
          body={`Once you have a client connected, ${lowercaseType} insights about their business will appear here. `}
          heading={uppercaseType}
        />
      </div>
    );
  }

  if (rootStore.pageStore.tabActive === InsightTab.overview) {
    return (
      <Overview />
    );
  }

  if (type === InsightType.DATAHEALTH) {
    return (
      <CheckList />
    );
  }

  if (type === InsightType.REPORT) {
    // If performance report feature flag is set, serve the new performance report
    return splitStore.featureEnabled(SplitEnums.FeatureFlags.ReportCustomisation) ? <PerformanceReportV2 /> : <PerformanceReport />;
  }

  if (type === InsightType.ADVISORY_AI) {
    return (
      <AdvisoryAi />
    );
  }

  const handlePeriodSelection = (newPeriod) => {
    rootStore.timePeriodStore.setProfitabilityPeriodSelected(newPeriod);
    rootStore.insightStore.selectedProfitabilityPeriod = newPeriod;
  };

  const handleTypeChange = (newType) => {
    rootStore.insightStore.activeType = newType.key;
  };

  const handleBudgetSelection = (newBudget) => {
    if (newBudget.key === 'new-simple-budget') {
      Notification({ type: 'info', title: 'Coming soon' });
    } else {
      rootStore.insightStore.selectedBudgetKey = newBudget.key;
    }
  };

  const comparisonItems: MenuProps['items'] = [
    {
      label: (<span className='beta-wrapper__inline'>vs. Budgets <TextPill>BETA</TextPill></span>),
      key: 'budgets',
      className: `budget-selector-item ${insightStore.activeType === 'budgets' ? 'budget-selector-item--active' : ''}`,
    },
    {
      label: 'vs. Historical Data',
      key: 'financials',
      className: `budget-selector-item ${insightStore.activeType === 'financials' ? 'budget-selector-item--active' : ''}`,
    },
  ];

  const comparisonMenu = {
    items: comparisonItems,
    onClick: handleTypeChange,
  };

  const budgetItems: MenuProps['items'] = [...insightStore.availableBudgets];

  if (splitStore.featureEnabled(SplitEnums.FeatureFlags.ManualBudget)) {
    budgetItems.push({
      icon: <PlusOutlined />,
      key: 'new-simple-budget',
      label: 'New simple budget',
      className: 'budget-selector-item__new'
    });
  }

  if (budgetItems.length === 0) {
    budgetItems.push({
      key: 'no-budgets',
      icon: <StopOutlined />,
      label: 'No budgets available',
      className: 'budget-selector-item__new'
    });
  }

  const budgetMenu = {
    items: budgetItems,
    onClick: handleBudgetSelection,
  };

  return (
    <>
      {insightData && type === InsightType.PROFITABILITY && (
        <div className='periodSelecterHeader mb-3'>
          <div className='periodSelecterHeader__block'>
            <PeriodGranularity id='profitability-tab-granularity' type={InsightType.PROFITABILITY} />
            {rootStore.timePeriodStore.profitabilityPeriodSelected && (
              <Select
                className='profitability-period-dropdown'
                defaultValue={rootStore.timePeriodStore.profitabilityPeriodSelected}
                onChange={handlePeriodSelection}
                size='large'
                options={rootStore.timePeriodStore.profitabilityPeriods}
                value={rootStore.timePeriodStore.profitabilityPeriodSelected}
              />
            )}

            <div className='periodSelecterHeader__block'>
              <Dropdown
                trigger={['click']}
                className='profitability-period-dropdown'
                menu={comparisonMenu}
              >
                <ButtonSecondary
                  className='title-colors reversed'
                  icon={<DownOutlined />}
                  shape='default'
                  size='large'
                >
                  {rootStore.insightStore.activeType === 'budgets' ? (
                    <span className='beta-wrapper__inline'>
                      vs. Budgets <TextPill>BETA</TextPill>
                    </span>
                  ) : 'vs. Historical Data'}
                </ButtonSecondary>
              </Dropdown>
            </div>

            {insightStore.activeType === 'budgets' && (
              <div className='periodSelecterHeader__block'>
                <Dropdown
                  trigger={['click']}
                  className='profitability-period-dropdown'
                  menu={budgetMenu}
                >
                  <ButtonSecondary
                    className='title-colors reversed'
                    icon={<DownOutlined />}
                    shape='default'
                    size='large'
                  >
                    {insightStore.selectedBudgetName
                    || (budgetMenu?.items?.length > 0 && budgetMenu?.items[0]?.key !== 'no-budgets')
                      ? insightStore?.selectedBudgetName || 'Select budget'
                      : 'No budgets available'}
                  </ButtonSecondary>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      )}
      <NoBudgetsBanner />
      <Insight
        insightDataArray={insightData}
        renderActionCentreModal={renderActionCentreModal}
        type={type}
        uppercaseType={uppercaseType}
        lowercaseType={lowercaseType}
        toggleModal={toggleModal}
        tabItems={TabItems}
      />
    </>
  );
};

export default observer(InsightComponent);
